import React from 'react';
import { Container, SectionTitle } from '@components';
import Image from '@images/About.svg';
import './styles.scss';

const AboutPirsos = () => (
  <section className="AboutPirsos" id="about_pirsos">
    <Container>
      <div className="AboutPirsos-wrapper">
        <div className="AboutPirsos-image">
          <img
            alt="Security Token"
            src={Image}
            width="100%"
          />
        </div>
        <div className="AboutPirsos-text">
          <div className="title">
            <SectionTitle title="What is Pirsos?" />
          </div>
          <div className="AboutPirsos-image-mobile">
            <img
              alt="Security Token"
              src={Image}
              width="100%"
            />
          </div>
          <div className="content">
            <p>Pirsos is an efficient and reliable mechanism for managing certificates and keys in a Kubernetes container environment, reducing product release cycles while maintaining your organization's security policies. Pirsos reduces the complexity of creating, storing, and renewing the public and private SSL/TLS X.509 certificates and keys that safeguard your Kubernetes deployments.</p>
          </div>
        </div>
      </div>
    </Container>
  </section>
);

export default AboutPirsos;