import React from 'react';
import { Container, SectionTitle } from '@components';
import Image from '@images/Contact.svg';
import './styles.scss';

const Contact = () => {
  const checkElement = async selector => {

    while (document.querySelector(selector) === null) {
        await new Promise(resolve => requestAnimationFrame(resolve))
    }
  
    document.querySelectorAll('.fserv-select option')[2].selected = true;
    return document.querySelector(selector).innerHTML = 'Standard'
  };

  React.useEffect(() => {
  
    const script = document.createElement('script')
    const container = document.getElementById("contactPage__formContainer")
    script.src = "https://licensespring.freshsales.io/web_forms/42a154dd8957185a458ea741e5b8e9e89696cd966f8687ccb912baf7b7411d58/form.js"
    script.async = true
    script.crossorigin = "anonymous"
    script.id = 'fs_42a154dd8957185a458ea741e5b8e9e89696cd966f8687ccb912baf7b7411d58'
    container.appendChild(script)
    
    checkElement('.select2-selection__rendered')
  
    return () => {
        container.removeChild(script);
  
    }
  
  }, []);

  return (
    <section className="Contact" id="contact">
      <Container>
        <div className="Contact-wrapper">
          <div className="Contact-image">
            <img
              alt="Contact us"
              src={Image}
              width="100%"
            />
          </div>
          <div className="Contact-text">
            <div className="title">
              <SectionTitle title="Contact us!" />
            </div>
            <div className="Contact-image-mobile">
              <img
                alt="Contact us"
                src={Image}
                width="100%"
              />
            </div>
            <div id="contactPage__formContainer" className="contactPage__formContainer" onChange={(e) => console.log(e)} />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
