import React from 'react';
import { Container, SectionTitle } from '@components';
import Image from '@images/Features.svg';
import './styles.scss';

const Features = () => (
  <section className="Features" id="features">
    <Container>
      <div className="Features-wrapper">
        <div className="Features-image">
          <img
            alt="Security Token"
            src={Image}
            width="100%"
          />
        </div>
        <div className="Features-text">
          <div className="title">
            <SectionTitle title="Features" />
          </div>
          <div className="Features-image-mobile">
            <img
              alt="Security Token"
              src={Image}
              width="100%"
            />
          </div>
          <div className="content">
            <p>Pirsos currently supports the following key Features:</p>
            <ul>
              <li>Manage Certificates for Kubernetes cluster using  existing PKI</li>
              <li>Certificate management for ingress controllers and applications via TLS </li>
              <li>Use Public Certificates issued by any Certificate Authority that supports the ACME v2 protocol</li>
              <li>Support for Java KeyStore</li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  </section>
);

export default Features;