import React from 'react';
import { Container, SectionTitle } from '@components';
import Image from '@images/Solutions.svg';
import './styles.scss';

const Solutions = () => (
  <section className="Solutions" id="benefits">
    <Container>
      <div className="Solutions-wrapper">
        <div className="Solutions-text">
          <div className="title">
            <SectionTitle title="Benefits" />
          </div>
          <div className="Solutions-image-mobile">
            <img
              alt="Solutions"
              src={Image}
              width="100%"
            />
          </div>
          <div className="content">
            <ul>
              <li>Implement and govern all Certificate Management policies</li>
              <li>Prevent Outages due to invalid or expired certificates</li>
              <li>Connect your PKI lifecycle management into your CICD framework</li>
              <li>Improve Authentication and Authorization through a centralized certificate management tool.</li>
            </ul>
          </div>
        </div>
        <div className="Solutions-image">
          <img
            alt="Solutions"
            src={Image}
            width="100%"
          />
        </div>
      </div>
    </Container>
  </section>
);

export default Solutions;