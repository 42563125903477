import React from 'react';
import SEO from "../components/seo"
import { Footer, Header, Page } from '@components';
import Hero from '@sections/home/Hero'
import Solutions from '@sections/home/Solutions'
import AboutPirsos from '@sections/home/AboutPirsos'
import Features from '@sections/home/Features'
import Contact from '@sections/home/Contact'

const IndexPage = () => (
  <Page>
    <SEO title="Home" />
    <Header />
    <Hero />
    <AboutPirsos />
    <Solutions />
    <Features />
    <Contact />
    <Footer />
  </Page>
)

export default IndexPage
