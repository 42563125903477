import React from 'react';
import { Link } from 'gatsby';
import { Container } from '@components';
import Image from '@images/Main.svg';
import './styles.scss';

const Hero = () => {
  return (
    <Container>
      <section className="Hero">
        <div className="Hero-text">
          <div className="title">
            Connect & Automate PKI lifecycle management for Kubernetes
          </div>
          <Link to="#contact" className="hero-btn" data-scroll>Request trial</Link>
        </div>
        <div className="Hero-image">
          <img
            src={Image}
            alt="Computer"
            width="700px"
          />
        </div>
      </section>
    </Container>
  );
};

export default Hero;